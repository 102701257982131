/* App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

.app {
  background-color: #5e548e;
  color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.heading {
  margin-bottom: 40px;
}

.toot-card-container {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.toot-card {
  background-color: #2b2b2b;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  max-width: 600px;
  width: 100%;
  margin: 20px 0;
  text-align: left;
  box-sizing: border-box;
  overflow-wrap: break-word; /* Ensure text wraps properly */
  word-wrap: break-word; /* For compatibility with older browsers */
  word-break: break-word; /* Break long words to prevent overflow */
  transform-origin: top left; /* Ensure scaling from top left */
}

.toot-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.profile-pic {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 15px;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.display-name {
  font-weight: bold;
  font-size: 1.2em;
}

.handle {
  color: rgb(240,242,245);
}

.toot-content {
  margin-bottom: 15px;
  overflow-wrap: break-word; /* Ensure text wraps properly */
  word-wrap: break-word; /* For compatibility with older browsers */
  word-break: break-word; /* Break long words to prevent overflow */
}

.toot-content a {
  color: rgb(100, 149, 237);
  text-decoration: none;
}

.toot-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
  color: #aaa;
  flex-wrap: nowrap; /* Ensure the footer is on a single line */
}

.toot-footer .stats {
  display: flex;
  gap: 10px;
}

.emoji {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}

/* Additional responsive styles to adjust font size and padding */
@media (max-width: 600px) {
  .app {
    padding: 10px;
  }

  .heading {
    font-size: 1.5em;
  }

  .toot-card {
    padding: 15px;
    font-size: 0.7rem;
  }

  .profile-pic {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}
